import { AppConfig, Environment } from 'shared-domain';

import { environment } from '../environments/environment';
import { companyFields } from './company';
import { affiliatedCompanyFields } from './affiliated-company';
import { individualFields } from './individual';
import { stepsConfig } from './step';

export const bootstrapConfig: Partial<Omit<AppConfig, 'environment'>> & {
  environment: Environment;
} = {
  websiteTitle: 'Relai',
  showPoweredLogo: false,
  languages: ['en', 'de'],
  queryParamsRequired: ['email', 'firstName', 'lastName'],
  supportLink: 'mailto:otc@relai.app',
  hasKyb: false,
  companyFields,
  affiliatedCompanyFields,
  individualFields,
  stepsConfig,
  environment,
  redirectUrl:
    'https://meetings-eu1.hubspot.com/mario-d-elena/relai-private-business?uuid=723d480d-152b-49a7-a4c1-e2e387bf2fe4',
};
